import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InvoiceItem from "../atoms/InvoiceItem";
import InvoiceModal from "./InvoiceModal";
import { Button, InputGroup } from "react-bootstrap";
import { getCurrencyValueLayuot } from '../utils/CurrencyLayout.helper';

export default function InvoiceForm() {

    const [state, setState] = useState({
        isOpen: false,
        currency: 'zł',
        dateOfIssue: '',
        dueDate: '',
        invoiceNumber: 1,
        seller: '',
        sellerTaxId: '',
        sellerAddress: '',
        buyer: '',
        buyerTaxId: '',
        buyerAddress: '',
        subTotal: '0.00',
        taxRate: 0,
        taxAmount: '0.00',
    })

    const [total, setTotal] = useState(0.00);

    const [items, setItems] = useState([{
        id: "0",
        name: "",
        description: "",
        price: 1.00,
        quantity: 1,
    }]);

    const handleChange = (event) => {
        setState((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
    }

    const onItemEdit = (event) => {
        const singleItem = {
            id: event.target.id,
            name: event.target.name,
            value: event.target.value
        }

        var newItems = items.map((item) => {
            for (let key in item) {
                if (key === singleItem.name && item.id === singleItem.id) {
                    item[key] = singleItem.value;
                }
            }

            return item;
        });

        setItems(newItems);
    }

    const handleAddEvent = () => {

        let id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
        let item = {
            id: id,
            name: "",
            description: "",
            price: 1.00,
            quantity: 1,
        };

        setItems((items) => [...items, item]);
    }

    const handleRowDelete = (item) => {
        if(items.length > 1) {
            setItems((items) => items.filter((data) => data.id !== item.id));
        } else {
            setItems([{
                id: "0",
                name: "",
                description: "",
                price: 1.00,
                quantity: 1,
            }]);
        }
    }

    const onCurrencyChange = (selectedOption) => {
        setState((state) => ({ ...state, ...selectedOption }));
    }

    useEffect(() => {
        const subTotal = (items.reduce((sum, invoiceItem) => sum + parseFloat(invoiceItem.quantity) * parseFloat(invoiceItem.price), 0)).toFixed(2);

        const taxAmount = parseFloat(parseFloat(subTotal) * parseFloat(state.taxRate/100)).toFixed(2);

        const total = (parseFloat(subTotal) + parseFloat(taxAmount)).toFixed(2);

        setTotal(total);

        setState((state) => ({
            ...state,
            subTotal,
            taxAmount,
        }));

    }, [items, state.taxRate]);

    return (
    <Form 
        onSubmit={(event) => {
            event.preventDefault();
            setState((state) => ({ ...state, isOpen: true }));
        }}
    >
        <Row>
            <Col md={8} lg={9}>
                <Card className="d-flex  p-4 p-xl-5 my-3 my-xl-4">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column">
                                <div className="mb-2 d-flex flex-row align-items-center">
                                    <span className="fw-bold text-nowrap">Data wystawienia:&nbsp;</span>
                                        <Form.Control 
                                            value={state.dateOfIssue}
                                            type="date"
                                            name="dateOfIssue"
                                            onChange={handleChange}
                                            style={{ maxWidth: '150px' }}
                                            required="required"
                                        />
                                </div>
                            </div>    
                            <div className="d-flex flex-row align-items-center">
                                <span className="fw-bold me-2 text-nowrap">Termin płatności:&nbsp;</span>
                                    <Form.Control 
                                        value={state.dueDate}
                                        type="date"
                                        name="dueDate"
                                        onChange={handleChange}
                                        style={{ maxWidth: '150px' }}
                                        required="required"
                                    />
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="mb-2">
                                <span className="fw-bold text-nowrap">Faktura numer:&nbsp;</span>
                            </div>
                                <Form.Control
                                    value={state.invoiceNumber} 
                                    type="number" 
                                    name="invoiceNumber"
                                    onChange={handleChange}
                                    min="1" style={{ maxWidth: '100px' }} 
                                    required="required"
                                />
                        </div>
                    </div>
                    <hr className="my-4"/>
                    <Row className="mb-5">
                        <Col>
                            <Form.Label className="fw-bold">Sprzedawca:</Form.Label>
                            <Form.Control 
                                value={state.seller}
                                type="text"
                                name="seller"
                                className="my-2"
                                onChange={handleChange}
                                autoComplete="seller"
                                required={true}
                            />
                            <Form.Label className="fw-bold">NIP:</Form.Label>
                            <Form.Control 
                                value={state.sellerTaxId}
                                type="text"
                                name="sellerTaxId"
                                className="my-2"
                                onChange={handleChange}
                                autoComplete="sellerTaxId"
                                required={true}
                            />
                            <Form.Label className="fw-bold">Adres:</Form.Label>
                            <Form.Control 
                                value={state.sellerAddress}
                                type="text"
                                name="sellerAddress"
                                className="my-2"
                                onChange={handleChange}
                                autoComplete="sellerAddress"
                                required={true}
                            />
                        </Col>
                        <Col>
                            <Form.Label className="fw-bold">Nabywca:</Form.Label>
                            <Form.Control 
                                value={state.buyer}
                                type="text"
                                name="buyer"
                                className="my-2"
                                onChange={handleChange}
                                autoComplete="buyer"
                                required={true}
                            />
                            <Form.Label className="fw-bold">NIP:</Form.Label>
                            <Form.Control 
                                value={state.buyerTaxId}
                                type="text"
                                name="buyerTaxId"
                                className="my-2"
                                onChange={handleChange}
                                autoComplete="buyerTaxId"
                                required={true}
                            />
                            <Form.Label className="fw-bold">Adres:</Form.Label>
                            <Form.Control 
                                value={state.buyerAddress}
                                type="text"
                                name="buyerAddress"
                                className="my-2"
                                onChange={handleChange}
                                autoComplete="buyerAddress"
                                required={true}
                            />
                        </Col>
                    </Row>
                    <InvoiceItem 
                        items={items} 
                        onItemEdit={onItemEdit} 
                        onRowAdd={handleAddEvent} 
                        onRowDelete={handleRowDelete} 
                        currency={state.currency} 
                    />
                    <Row className="mt-4 justify-content-end">
                        <Col lg={6}>
                            <div className="d-flex flex-row align-items-start justify-content-between">
                                <span className="fw-bold">Netto:</span>
                                <span>{getCurrencyValueLayuot(state.currency, state.subTotal)}</span>
                            </div>
                            <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                                <span className="fw-bold">VAT ({state.taxRate || 0}%):</span>
                                <span>
                                    {getCurrencyValueLayuot(state.currency, state.taxAmount)}
                                </span>
                            </div>
                            <hr/>
                            <div className="d-flex flex-row align-items-start justify-content-between" style={{
                                fontSize: '1.125rem'
                            }}>
                                <span className="fw-bold">Brutto:</span>
                                <span className="fw-bold">{getCurrencyValueLayuot(state.currency, total)}</span>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col md={4} lg={3}>
            <div className="sticky-top pt-md-3 pt-xl-4">
                <Button 
                    variant="primary" 
                    type="submit" 
                    className="d-block w-100 mb-3"
                >
                    Podejrzyj fakturę
                </Button>
                <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">Waluta:</Form.Label>
                    <Form.Select 
                        onChange={(event) => onCurrencyChange({ currency: event.target.value })}
                    >
                        <option value="zł">PLN</option>
                        <option value="€">EURO</option>
                        <option value="$">USD</option>
                        <option value="₿">BTC</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label className="fw-bold">Stawka Vat:</Form.Label>
                    <InputGroup className="my-1 flex-nowrap" >
                        <Form.Control
                            name="taxRate"
                            type="number"
                            value={state.taxRate}
                            onChange={handleChange}
                            className="bg-white-border"
                            placeholder="0.00"
                            min="0.00"
                            step="0.01"
                            max="100.00"
                        />
                        <InputGroup.Text className="bg-light fw-bold text-secondary small">%</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
            </div>
            </Col>
        </Row>
        <InvoiceModal 
            showModal={state.isOpen} 
            closeModal={() => setState((state) => ({ ...state, isOpen: false }))}
            info={state}
            items={items}
            total={total}
        />
    </Form>
    )
}