import React from 'react';
import { Button, Table } from 'react-bootstrap';
import EditableField from './EditableField';
import { BiTrash } from 'react-icons/bi';

export default function InvoiceItem(props) {
    let itemTable = props.items.map(item => 
    <ItemRow 
        onItemEdit={props.onItemEdit} 
        item={item} 
        onDeleteEvent={props.onRowDelete} 
        key={item.id} 
        currency={props.currency} 
    />);

    return (
        <div>
          <Table>
            <thead>
              <tr>
                <th>Pozycje na fakturze</th>
                <th>Ilość</th>
                <th>Cena</th>
                <th className="text-center">Usuń</th>
              </tr>
            </thead>
            <tbody>
                {itemTable}
            </tbody>
          </Table>
          <Button className="fw-bold" onClick={props.onRowAdd}>Dodaj pozycję</Button>
        </div>
      )
}

function ItemRow(props) {

    const onDeleteEvent = () => {
        props.onDeleteEvent(props.item)
    }

    return (
        <tr>
            <td style={{width: '100%'}}>
                <EditableField 
                    onItemEdit={props.onItemEdit}
                    cellData={{
                        type: 'text',
                        name: 'name',
                        placeholder: 'nazwa',
                        value: props.item.name,
                        id: props.item.id
                    }}
                />
                <EditableField 
                    onItemEdit={props.onItemEdit}
                    cellData={{
                        type: 'text',
                        name: 'description',
                        placeholder: 'opis',
                        value: props.item.description,
                        id: props.item.id
                    }}
                />
            </td>
            <td style={{minWidth: '70px'}}>
            <EditableField 
                    onItemEdit={props.onItemEdit}
                    cellData={{
                        type: 'number',
                        name: 'quantity',
                        min: 1,
                        step: '1',
                        value: props.item.quantity,
                        id: props.item.id
                    }}
                />
            </td>
            <td style={{minWidth: '130px'}}>
            <EditableField 
                    onItemEdit={props.onItemEdit}
                    cellData={{
                        leading: props.currency,
                        type: 'number',
                        name: 'price',
                        min: 1,
                        step: '0.01',
                        textAlign: 'text-end',
                        value: props.item.price,
                        id: props.item.id
                    }}
                />
            </td>
            <td className='text-center' style={{minWidth: 50}}>
                <BiTrash
                    onClick={onDeleteEvent}
                    style={{height: '33px', width: '33px', padding: '7.5px'}}
                    className='text-white mt-1 btn btn-danger'
                />
            </td>
        </tr>
    )
}