import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import InvoiceForm from './components/molecules/InvoiceForm';
import Container from 'react-bootstrap/Container';



function App() {
  return (
    <div className="App d-flex flex-column align-items-center justify-content-center w-100">
      <Container>
        <InvoiceForm/>
      </Container>
    </div>
  );
}

export default App;
