import React from 'react';
import printJS from "print-js";
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { BiPrinter } from 'react-icons/bi';
import { getCurrencyValueLayuot } from '../utils/CurrencyLayout.helper';

export default function InvoiceModal(props) {
    const handlePrintInvoice = () => {
      printJS({
        printable: "invoicecapture",
        type: "html",
        css: [
          "https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css"
        ],
        scanStyles: false
      });
    };
      
    return (
        <Modal
            show={props.showModal}
            onHide={props.closeModal}
            size='lg'
            centered
        >
            <div id="invoicecapture">
            <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
              <div className="w-100">
                <h4 className="fw-bold my-2">
                    {props.info.buyer}
                </h4>
                <h6 className="fw-bold text-secondary mb-1">
                  Faktura nr: {props.info.invoiceNumber}
                </h6>
              </div>
            </div>
            <div className="p-4">
              <Row className="mb-4">
                <Col md={4}>
                  <div className="fw-bold">Sprzedawca:</div>
                  <div>{props.info.seller||''}</div>
                  <div>{props.info.sellerAddress||''}</div>
                  <div>{props.info.sellerTaxId||''}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold">Nabywca:</div>
                  <div>{props.info.buyer||''}</div>
                  <div>{props.info.buyerAddress||''}</div>
                  <div>{props.info.buyerTaxId||''}</div>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      <div className="fw-bold">Data wystawienia:</div>
                      <div>{props.info.dateOfIssue}</div>
                    </Col>
                    <Col md={6}>
                      <div className="fw-bold">Termin płatności:</div>
                      <div>{props.info.dueDate}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th>Ilość</th>
                    <th>Opis</th>
                    <th className="text-end">Cena jednostkowa</th>
                    <th className="text-end">Wartość netto</th>
                  </tr>
                </thead>
                <tbody>
                  {props.items.map((item, i) => {
                    return (
                      <tr id={i} key={i}>
                        <td style={{width: '70px'}}>
                          {item.quantity}
                        </td>
                        <td>
                          {item.name} - {item.description}
                        </td>
                        <td className="text-end" style={{width: '100px'}}>
                          {getCurrencyValueLayuot(props.info.currency, item.price)}
                        </td>
                        <td className="text-end" style={{width: '120px'}}>
                          {getCurrencyValueLayuot(props.info.currency, (item.price * item.quantity).toFixed(2))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{width: '100px'}}>NETTO</td>
                    <td className="text-end" style={{width: '120px'}}>{getCurrencyValueLayuot(props.info.currency, props.info.subTotal)}</td>
                  </tr>
                  {props.info.taxAmount !== 0.00 &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{width: '100px'}}>VAT</td>
                      <td className="text-end" style={{width: '120px'}}>{getCurrencyValueLayuot(props.info.currency, props.info.taxAmount)}</td>
                    </tr>
                  }
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{width: '100px'}}>BRUTTO</td>
                    <td className="text-end" style={{width: '120px'}}>{getCurrencyValueLayuot(props.info.currency, props.total)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={12} lg={6}>
                <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={handlePrintInvoice}>
                  <BiPrinter style={{width: '16px', height: '16px', marginTop: '-3px'}} className="me-2"/>
                  Wydrukuj fakturę
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
    )
}